<template>
    <Layout>
        <h4>New Maintenance Request</h4>
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestMaintenance"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="maintenance.unit_id"
                            :options="mappedUserUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.maintenance.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.maintenance.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.maintenance.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"> Date For Visit*</label>
                        <b-form-datepicker
                            v-model="maintenance.available_date"
                            :min="min"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.maintenance.available_date.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="
                                submitted &&
                                $v.maintenance.available_date.$error
                            "
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.maintenance.available_date.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <label for="ownerfname">
                        Description of the complaint*</label
                    >
                    <div class="form-group">
                        <b-form-textarea
                            v-model="complaint"
                            id="textarea-small"
                            size="sm"
                            :class="{
                                'is-invalid': submitted && $v.complaint.$error,
                            }"
                        ></b-form-textarea>
                        <div
                            v-if="submitted && $v.complaint.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.complaint.required"
                                >Complaint is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="ownerfname">Number of complaints</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="complaintNumber"
                            @change="addMoreComplaint"
                            :options="options"
                        ></b-form-select>
                    </div>
                </div>
            </div>
            <div
                v-for="(complaint, index) in maintenance.complaints"
                :key="index"
                class="row"
            >
                <div class="col-lg-12">
                    <label for="ownerfname">
                        Description of the complaint</label
                    >
                    <b-form-textarea
                        v-model="maintenance.complaints[index]"
                        id="textarea-small"
                        size="sm"
                        class="mt-3 mb-3"
                        :class="{
                            'is-invalid':
                                submitted &&
                                maintenance.complaints[index] == '',
                        }"
                    ></b-form-textarea>
                    <div
                        v-if="submitted && maintenance.complaints[index] == ''"
                        class="invalid-feedback"
                    >
                        <span v-if="maintenance.complaints[index] == ''"
                            >Complaint is required
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <label for="ownerfname">Attach Upto 5 Files</label>
                    <b-form-file
                        @change="handleComplaintFiles"
                        placeholder="Upload upto 5 files"
                        drop-placeholder="Drop file here..."
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        multiple
                    ></b-form-file>
                </div>
            </div>
            <submitButton />
        </form>
    </Layout>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import Layout from "../../layouts/main.vue";
import DatePicker from "vue2-datepicker";
import alertMixin from "../../../mixins/alertMixin";
import { required } from "vuelidate/lib/validators";
import { BASE_URL } from "../../common";
import axios from "axios";
import submitButton from "../../../components/submitButton.vue";

export default {
    mixins: [alertMixin],
    validations: {
        maintenance: {
            available_date: { required },
            unit_id: { required },
            complaints: {},
        },
        complaint: { required },
    },
    components: {
        Layout,
        submitButton,
        DatePicker,
    },
    data() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const minDate = new Date(today);
        return {
            submitted: false,
            fullPage: false,
            min: minDate,
            files: [],

            complaintNumber: "",
            options: [
                { value: null, text: "Choose a number" },
                { value: "1", text: "1" },
                { value: "2", text: "2" },
                { value: "3", text: "3" },
                { value: "4", text: "4" },
            ],
            complaint: "",
            maintenance: {
                available_date: "",
                complaints: [],
                file1: "",
                file1_filename: "",
                file2: "",
                file2_filename: "",
                file3: "",
                file3_filename: "",
                file4: "",
                file4_filename: "",
                file5: "",
                file5_filename: "",
                unit_id: "",
            },
        };
    },
    computed: {
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
    methods: {
        addMoreComplaint() {
            this.maintenance.complaints = [];
            for (let index = 0; index < this.complaintNumber; index++) {
                this.maintenance.complaints.push("");
            }
        },
        requestMaintenance() {
            console.log("form submitted");
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log(this.maintenance);
                if (this.maintenance.complaints.includes("")) {
                    return;
                }
                this.maintenance.complaints.push(this.complaint);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}maintenances?token=${localStorage.cs_user_token}`,
                            this.maintenance
                        )
                        .then((res) => {
                            console.log(res);
                            this.resetFields();
                            loader.hide();
                            if (res.data.status_code == 403) {
                                this.showError(res.data.msg);
                            } else if (res.data.status_code == 200) {
                                this.successMsg(
                                    "New maintenance request submitted",
                                    "Thank you"
                                );
                                this.$router.push("/");
                            }
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },

        handleComplaintFiles(event) {
            let files = event.target.files;
            if (files.length > 5) {
                this.showError("You cannot upload more than 5 documents");
                return;
            }
            for (let i = 0; i < files.length; i++) {
                switch (i) {
                    case 0:
                        this.maintenance.file1_filename = files[i].name;
                        this.createBase64String(event, i);
                        break;
                    case 1:
                        this.maintenance.file2_filename = files[i].name;
                        this.createBase64String(event, i);
                        break;
                    case 2:
                        this.maintenance.file3_filename = files[i].name;
                        this.createBase64String(event, i);
                        break;
                    case 3:
                        this.maintenance.file4_filename = files[i].name;
                        this.createBase64String(event, i);
                        break;
                    case 4:
                        this.maintenance.file5_filename = files[i].name;
                        this.createBase64String(event, i);
                        break;
                }
            }
        },

        createBase64String(e, index) {
            let reader = new FileReader();
            console.log("base for", e.target.files[index].name);
            reader.onload = (e) => {
                switch (index) {
                    case 0:
                        this.maintenance.file1 = e.target.result.split(",")[1];
                        console.log(this.maintenance.file1);
                        break;
                    case 1:
                        this.maintenance.file2 = e.target.result.split(",")[1];
                        console.log(this.maintenance.file2);
                        break;
                    case 2:
                        this.maintenance.file3 = e.target.result.split(",")[1];
                        console.log(this.maintenance.file3);
                        break;
                    case 3:
                        this.maintenance.file4 = e.target.result.split(",")[1];
                        console.log(this.maintenance.file4);
                        break;
                    case 4:
                        this.maintenance.file5 = e.target.result.split(",")[1];
                        console.log(this.maintenance.file5);
                        break;
                }
            };
            reader.readAsDataURL(e.target.files[index]);
        },
    },
};
</script>
<style scoped>
.description_title {
    text-align: center;
}
.add_more {
    text-align: center;
}
</style>
