var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("New Maintenance Request")]),_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.requestMaintenance.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.maintenance.unit_id.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedUserUnits},model:{value:(_vm.maintenance.unit_id),callback:function ($$v) {_vm.$set(_vm.maintenance, "unit_id", $$v)},expression:"maintenance.unit_id"}}),(_vm.submitted && _vm.$v.maintenance.unit_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.maintenance.unit_id.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Date For Visit*")]),_c('b-form-datepicker',{class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.maintenance.available_date.$error,
                        },attrs:{"min":_vm.min},model:{value:(_vm.maintenance.available_date),callback:function ($$v) {_vm.$set(_vm.maintenance, "available_date", $$v)},expression:"maintenance.available_date"}}),(
                            _vm.submitted &&
                            _vm.$v.maintenance.available_date.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.maintenance.available_date.required)?_c('span',[_vm._v("Date is required ")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Description of the complaint*")]),_c('div',{staticClass:"form-group"},[_c('b-form-textarea',{class:{
                            'is-invalid': _vm.submitted && _vm.$v.complaint.$error,
                        },attrs:{"id":"textarea-small","size":"sm"},model:{value:(_vm.complaint),callback:function ($$v) {_vm.complaint=$$v},expression:"complaint"}}),(_vm.submitted && _vm.$v.complaint.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.complaint.required)?_c('span',[_vm._v("Complaint is required ")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Number of complaints")]),_c('b-form-select',{attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.options},on:{"change":_vm.addMoreComplaint},model:{value:(_vm.complaintNumber),callback:function ($$v) {_vm.complaintNumber=$$v},expression:"complaintNumber"}})],1)])]),_vm._l((_vm.maintenance.complaints),function(complaint,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Description of the complaint")]),_c('b-form-textarea',{staticClass:"mt-3 mb-3",class:{
                        'is-invalid':
                            _vm.submitted &&
                            _vm.maintenance.complaints[index] == '',
                    },attrs:{"id":"textarea-small","size":"sm"},model:{value:(_vm.maintenance.complaints[index]),callback:function ($$v) {_vm.$set(_vm.maintenance.complaints, index, $$v)},expression:"maintenance.complaints[index]"}}),(_vm.submitted && _vm.maintenance.complaints[index] == '')?_c('div',{staticClass:"invalid-feedback"},[(_vm.maintenance.complaints[index] == '')?_c('span',[_vm._v("Complaint is required ")]):_vm._e()]):_vm._e()],1)])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Attach Upto 5 Files")]),_c('b-form-file',{attrs:{"placeholder":"Upload upto 5 files","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf","multiple":""},on:{"change":_vm.handleComplaintFiles}})],1)]),_c('submitButton')],2)])}
var staticRenderFns = []

export { render, staticRenderFns }